











































import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { CloneFolderRequest, NameValueResource } from '@/store/types'

@Component({
  components: {
    Autocomplete,
    Dialog,
    ModalWrapper,
    Select,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FolderCopyModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private selectFolderID!: number | null

  @Prop({ required: true })
  private courseID!: number

  @Prop({ required: true })
  private monthID!: number

  private courses: NameValueResource[] = []
  private months: NameValueResource[] = []

  private form: CloneFolderRequest = {
    courseId: '' as unknown as number,
    courseFolderId: '' as unknown as number,
  }

  private search: string | null = null

  private copyFolder() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result && this.selectFolderID) {
          ManagerDepositoryModule.copyFolderCourse({
            body: this.form,
            courseID: this.courseID,
            courseFolderID: this.monthID,
            mediaFolderID: this.selectFolderID,
          })
            .then(() => {
              this.handleClose()
              this.notifySuccess('Папка успешно скопирована в выбранный курс')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Bind()
  @Debounce(300)
  private fetchCourses() {
    if (this.visible)
      ManagerExercisesModule.fetchCourses({
        query: this.search ?? undefined,
      })
        .then(response => {
          this.courses = response
        })
        .catch(this.notifyError)
  }

  private fetchFolders(courseId: number) {
    this.form.courseFolderId = '' as unknown as number
    ManagerDepositoryModule.fetchFoldersCourse({
      courseId,
      params: {
        page: 1,
        perPage: 0,
      },
    })
      .then(response => {
        this.months = response.data.map(month => ({ name: month.title, value: month.id }))
      })
      .catch(this.notifyError)
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private handleSearch() {
    this.fetchCourses()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      if (!this.courses.length) {
        this.fetchCourses()
      }
    } else {
      this.search = null
      this.months = []
      requestAnimationFrame(() => ((this.$refs as any).form.reset()))
      this.form = {
        courseId: '' as unknown as number,
        courseFolderId: '' as unknown as number,
      }
    }
  }
}
